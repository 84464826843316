@use 'styles/theme/theme' as theme;

.leftBlock {
  display: flex;
  align-items: center;
  @media screen and (max-width: 1280px) {
    margin: 0;
    padding: 0;
    list-style: none;
    column-gap: 10px;
  }
  &__logoImage {
    height: 13px;
    margin-right: 3px;
  }
}

.proTools {
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 0;
  margin: 0;
}

.proToolsDropdown {
  padding-top: 2px;
  padding-right: 8px;
}

.arrowDownIcon {
  fill: theme.$primaryGrey !important;
}

.turnLeftIcon {
  margin-left: 4px;
  fill: theme.$primaryGrey !important;
  cursor: pointer;
}

.divider {
  width: 1px;
  height: 10px;
  background: theme.$secondaryGrey;
  margin: 0 10px 0 6px;
}

.navItem {
  padding: 0 8px;
  list-style: none;
  &__selected {
    border-radius: 3px;
    border: 1px solid theme.$primaryGrey;
    background: theme.$white;
    padding: 3px 10px;
  }
  @media screen and (max-width: 1280px) {
    padding: 0;
  }
}

.publicSiteLink {
  display: flex;
  align-items: center;
  &:hover {
    .turnLeftIcon {
      fill: theme.$blue !important;
    }
  }
  @media screen and (max-width: 1280px) {
    padding-right: 8px;
  }
}

.menuIcon {
  fill: theme.$primaryGrey !important;
}

.menu {
  padding: 0 !important;
  margin-top: 3px;
  &:hover {
    span {
      color: theme.$blue;
    }
    svg {
      fill: theme.$blue !important;
    }
  }
}

.proToolsScroller {
  display: flex;
  align-items: center;
  .navItem {
    padding: 3px 8px 4px 8px;
    min-width: max-content;
    &:last-child {
      padding-right: 16px;
    }
  }
  .proToolsDropdown {
    padding-right: 6px;
  }
  @media screen and (max-width: 1280px) {
    padding: 0 16px;
  }
}
