@use 'styles/theme/theme' as theme;

.bottomBlock {
  display: flex;
  height: 30px;
}

.links {
  display: flex;
  column-gap: 34px;
  margin: 0;
  padding: 0;
  list-style: none;
  margin-left: 34px;
}

.mobileBottomBlock {
  display: flex;
  margin: 0;
  padding: 7px 16px 0 16px;
  column-gap: 14px;
  list-style: none;
}

.navItem {
  list-style: none;
  @media screen and (max-width: 1280px) {
    &:last-child {
      padding-right: 16px;
    }
  }
}

.menuSection {
  display: flex;
  align-items: center;
  margin-top: -9px;
}

.menu {
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 2px;
  &:hover {
    .menuIcon {
      fill: theme.$blue !important;
    }
  }
}

.icon {
  fill: theme.$tertiaryGrey !important;
  margin-left: 2px !important;
}

.menuIcon {
  fill: theme.$secondaryGrey !important;
}

.divider {
  width: 1px;
  height: 9px;
  background-color: theme.$secondaryGrey;
  margin: 0 23px;
}
