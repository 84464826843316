@use 'styles/theme/theme' as theme;

.rightBlock {
  display: flex;
  align-items: center;
  column-gap: 16px;
  margin: 0;
  padding: 0;
  list-style: none;
}

.navItem {
  padding: 4px 8px;
  list-style: none;
}

.rightBlockLoggedIn {
  display: flex;
  align-items: center;
  column-gap: 4px;
  margin: 0;
  padding: 0;
  list-style: none;
  border-radius: 4px;
  border: 1px solid theme.$secondaryGrey;
  padding-left: 6px;
  padding-right: 4px;
}

.accountIcon {
  fill: theme.$white !important;
}

.profileDropdown {
  border-left: 1px solid theme.$secondaryGrey;
  height: 34px;
  &__withoutBorder {
    border-left: none;
    padding-bottom: 4px;
  }
}

.profileLink {
  border: 1px solid theme.$borderGrey;
  background: theme.$whiteSmoke;
}

.profileItem {
  display: flex;
}
