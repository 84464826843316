@use 'styles/theme/theme' as theme;

.recentSearches {
  padding: 21px 16px 0px 16px;
  height: 100%;
  overflow-y: auto;

  &__mobile {
    height: unset;
  }

  &__title {
    display: block;
    border-bottom: 1px solid theme.$borderGrey;
    padding-bottom: 11px;
    margin-bottom: 9px;
  }
  &__items {
    display: flex;
    flex-direction: column;
    row-gap: 18px;
    margin-bottom: 20px;
  }
}
