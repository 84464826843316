@use 'styles/theme/theme' as theme;

.history {
  &__sticky {
    padding: 8px 14px;
    border-radius: 50px;
    border: 1px solid theme.$primaryGrey;
  }
  &__selected {
    .title {
      color: theme.$blue;
    }
    svg {
      fill: theme.$blue !important;
    }
  }
  &:hover {
    .title {
      color: theme.$blue;
    }
    svg {
      fill: theme.$blue !important;
    }
  }
}

.historyIcon {
  fill: theme.$primaryGrey !important;
}

.popperContainer {
  width: 343px;
  height: calc(100vh - 210px);
  margin-top: 8px;
  border-radius: 8px;
  border: 1px solid theme.$tertiaryGrey;
  background: #fff;
  box-shadow: 0 0 6px 0 theme.$blackT25, 0 0 4px 0 theme.$blackT25;
}

.noResult {
  display: block;
  margin: 16px;
}
