@use 'styles/theme/theme' as theme;

.header {
  width: 100%;
  position: sticky;
  top: 0;
  background: theme.$white;
  z-index: 116;
  box-shadow: 0px 2px 6px 0px theme.$blackT10;
  &__content {
    display: flex;
    flex-direction: column;
  }
  &__sectionTopContainer {
    background-color: theme.$orangeLight;
    padding: 0 12px;
  }
  &__sectionTop {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 0;
    max-width: 1280px;
    margin: 0 auto;
    height: 53px;
  }
  &__sectionBottomContainer {
    background-color: theme.$orangeLight5;
    padding: 0 12px;
  }
  &__sectionMiddle {
    display: flex;
    align-items: center;
    max-width: 1280px;
    margin: 0 auto;
    padding-top: 22px;
    &_home {
      padding-bottom: 24px;
    }
  }
  &__sectionBottom {
    display: flex;
    align-items: center;
    column-gap: 16px;
    max-width: 1280px;
    margin: 0 auto;
    padding-top: 29px;
    padding-bottom: 24px;
    &_casting {
      padding: 24px 0;
    }
  }
  &__logoSection {
    display: flex;
    flex-direction: column;
    text-align: right;
    column-gap: 7px;
    margin-right: 40px;
    &:hover {
      text-decoration: none !important;
      color: theme.$secondaryGrey;
    }
    &_since {
      width: 100%;
    }
    &_title {
      width: 184px;
    }
    &_img {
      width: 113px;
    }
  }
  &__oldGlobalSearch {
    min-width: 288px;
    max-width: 288px;
    &_casting {
      min-width: calc(100% - 184px);
      max-width: calc(100% - 184px);
    }
  }
}

.static {
  position: static;
}

.hideHeader {
  transform: translateY(-100%);
}

.mobileHeader {
  width: 100%;
  position: sticky;
  top: 0;
  background: theme.$white;
  border-bottom: 1px solid theme.$borderGrey;
  z-index: 116;
  &__sectionTop {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 16px 6px;
    border-bottom: 1px solid theme.$borderGrey;
    background-color: theme.$orangeLight;
  }
  &__sectionMiddle {
    background-color: theme.$orangeLight;
    padding: 12px 0;
  }
  &__sectionBottom {
    background: theme.$orangeLight5;
    box-shadow: 0 2px 5px 0 theme.$blackT20;
    padding-bottom: 12px;
    &_casting {
      padding: 12px 0;
    }
    &_withSearch {
      padding-bottom: 18px;
    }
  }
  &__searchSection {
    padding: 0 16px;
  }
}
